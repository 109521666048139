import com.ccfraser.muirwik.components.styles.useMediaQuery
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.html.VIDEO
import react.dom.*
import react.fc
import styled.*
import styles.MainStyles
import utils.styledVideoMuted

val mobileQuery = "only screen and (max-width: 768px)"

fun main() {
    injectGlobal(MainStyles.globalCssHtml)
    injectGlobal(MainStyles.globalCssBody)

    window.onload = {
        render(document.getElementById("root")) {
            styledDiv {
                css { +MainStyles.main }
                styledDiv {
                    css { +MainStyles.app }
                    child(fc {
                        val isMobile = useMediaQuery(mobileQuery)
                        if (isMobile) {
                            // Mobile
                            styledVideo {
                                setProp("muted", true)
                                attrs {
                                    controls = false
                                    autoPlay = true
                                    loop = true
                                }
                                css { +MainStyles.bannerVideo }
                                source {
                                    attrs {
                                        type = "video/webm"
                                        src = "mobile_30.webm"
                                    }
                                }
                            }

                        } else {
                            styledVideo {
                                css { +MainStyles.bannerVideo }
                               setProp("muted", true)
                                attrs {
                                    controls = false
                                    autoPlay = true
                                    loop = true

                                }
                                source {
                                    attrs {
                                        type = "video/webm"
                                        src = "widescreen_30.webm"
                                    }
                                }
                            }
                        }

                    })
                }
            }
//                styledDiv {
//                    css {
//                        position = Position.sticky
//                        bottom = LinearDimension("0")
//                        left = LinearDimension("0")
//                        right = LinearDimension("0")
//                        textAlign = TextAlign.center
//                        color = Color.white
//                        backgroundColor = Color.black.withAlpha(0.9)
//                    }
//                    +"Made 100% with pure Kotlin"
//                }
        }
    }
}


