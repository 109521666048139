package pages

import com.ccfraser.muirwik.components.mCssBaseline
import components.container
import kotlinx.html.id
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.attrs
import react.dom.h1
import react.dom.p
import styled.StyleSheet
import styled.css
import styled.styledDiv
import styled.styledSection
import styles.MainStyles

@JsExport
class MediaPage : RComponent<MediaProps, MediaState>() {
    init {
        state = MediaState()
    }


    override fun RBuilder.render() {
        styledSection {
            container {
                mCssBaseline()
                attrs { id = "media" }
                css { +MainStyles.article }
                h1 { +"\$ media" }
                styledDiv {
                    p {
                        +"""Ich bin Christoph Landsky und möchte die Gelegenheit nutzen, mich kurz vorzustellen.
                    """.trimIndent()
                    }
                }
            }
        }
    }
}

//data class pages.MediaState(val name: String) : State {}
class MediaState() : State {}
external interface MediaProps : Props {
    //val name: String
}

object MediaStyle : StyleSheet("Media") {
}

fun RBuilder.media() {
    child(MediaPage::class) {}
}