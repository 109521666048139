package components

import kotlinx.css.Overflow
import kotlinx.css.em
import kotlinx.css.overflowY
import kotlinx.css.padding
import kotlinx.html.DIV
import react.RBuilder
import styled.StyledDOMBuilder
import styled.css
import styled.styledDiv
import styles.MainStyles

fun RBuilder.container(block: StyledDOMBuilder<DIV>.() -> Unit) {
    styledDiv {
        css {
            media("only screen and (min-width: 768px)") {
                padding(vertical = 1.em, horizontal = 8.em)
            }

            media("only screen and (max-width: 768px)") {
                padding(all = 2.em)
            }
        }
        block()
    }
}