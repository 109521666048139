import components.container
import pages.media
import pages.software
import pages.whoami
import react.*
import styled.StyleSheet
import styled.styledDiv

@JsExport
class Home : RComponent<HomeProps, HomeState>() {
    init {
        state = HomeState()
    }

    override fun RBuilder.render() {
        styledDiv {
                banner()
                whoami()
                software()
                media()
        }
    }
}

//data class HomeState(val name: String) : RState {}
class HomeState() : State {}
external interface HomeProps : Props {
    //val name: String
}

object HomeStyle : StyleSheet("Home") {

}

fun RBuilder.home() {
    child(Home::class) {}
}