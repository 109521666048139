package styles

import kotlinx.css.*
import kotlinx.css.properties.border
import kotlinx.css.properties.borderTop
import styled.StyleSheet
import utils.zero

object MainStyles : StyleSheet("MainStyles", isStatic = true) {
    val main by css {
        display = Display.flex
        flexDirection = FlexDirection.column
        position = Position.absolute
        left = LinearDimension("0")
        top = LinearDimension("0")
        right = LinearDimension("0")
        bottom = LinearDimension("0")
        backgroundColor = Color.black
//        background = "linear-gradient(rgba(82,126,160,.8),rgba(71,24,24,.8));"
        fontFamily = "'Fira Code', monospace"
        color = Color.white


        media("only screen and (max-width: 768px)") {
            p {
                wordWrap = WordWrap.breakWord
                wordBreak = WordBreak.breakWord
                hyphens = Hyphens.auto
            }
            /* For mobile phones: */
            "[class*=\"col-\"]" {
                width = LinearDimension("100%")
            }
        }
    }

    val bannerVideo by css {
        display = Display.block
        overflow = Overflow.hidden
        objectFit = ObjectFit.cover
        margin(zero, LinearDimension.auto)

        media("only screen and (max-aspect-ratio: 16/9)") {
            minHeight = 100.vh
            height = 100.vh
            maxHeight = 100.vh
            maxWidth = 100.vw
        }

        media("only screen and (min-aspect-ratio: 16/9)") {
            minWidth = 100.vw
            width = 100.vw
            maxWidth = 100.vh
            maxHeight = 100.vh
        }

    }

    val article by css {
//        marginBottom = LinearDimension("1em")
        borderTop(width = 2.px, style = BorderStyle.solid, Color.black.withAlpha(0.7))
    }
    val app by css {
        overflowY = Overflow.scroll
    }

    val globalCssBody = CssBuilder(allowClasses = false).apply {
        body {
            scrollBehavior = ScrollBehavior.smooth
            margin(0.px)
            padding(0.px)
        }
        img {
            height = LinearDimension("100%")
            width = LinearDimension("100%")
        }
    }

    val globalCssHtml = CssBuilder(allowClasses = false).apply {
        html {
            scrollBehavior = ScrollBehavior.smooth
        }
    }


}



