package components.typed

import react.RBuilder

fun RBuilder.typed(className: String = "", strings: Array<String>, speed: Number = 40, typedRef: (Typed) -> Unit) {
    child(Typed::class) {
        attrs {
            this.className = className
            this.typedRef = typedRef
            this.strings = strings
            this.typeSpeed = speed
        }
    }
}