package components.mdi

import kotlinx.css.Color
import react.RBuilder

fun RBuilder.mdIcon(path: MDIconPath, color: Color = Color.white, size: Number = 1.0) {
    Icon {
        attrs {
            this.path = path
            this.color = color.value
            this.size = size
        }
    }
}