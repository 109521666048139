package pages

import components.container
import kotlinx.css.*
import kotlinx.html.id
import react.RBuilder
import react.RComponent
import react.Props
import react.State
import react.dom.attrs
import react.dom.h1
import react.dom.h3
import react.dom.p
import styled.*
import styles.MainStyles

@JsExport
class Software : RComponent<SoftwareProps, SoftwareState>() {
    init {
        state = SoftwareState()
    }

    override fun RBuilder.render() {
        styledSection {
            css {
                backgroundColor = Color.darkGray.darken(85).changeAlpha(0.6)
            }
            container {
                attrs { id = "software" }
                css { +MainStyles.article }
                h1 { +"\$ software" }

                styledImg(src = "img/wordcloud.svg") {
                    css {
                        maxWidth = 100.pct
                        maxHeight = 70.vh
                        objectFit = ObjectFit.contain
                    }
                }
                h3 { +"Meine Philosophie" }
                p {
                    +"""Ich bin Christoph Landsky und möchte die Gelegenheit nutzen, mich kurz vorzustellen.
                    """.trimIndent()
                }
            }
        }
    }

}

//data class SoftwareState(val name: String) : State {}
class SoftwareState() : State {}
external interface SoftwareProps : Props {
    //val name: String
}

object SoftwareStyle : StyleSheet("Software") {

}

fun RBuilder.software() {
    child(Software::class) {}
}