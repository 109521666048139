package pages

import com.ccfraser.muirwik.components.MGridSize
import com.ccfraser.muirwik.components.mCssBaseline
import com.ccfraser.muirwik.components.mGridContainer
import com.ccfraser.muirwik.components.mGridItem
import components.container
import kotlinx.css.*
import kotlinx.html.id
import react.Props
import react.RBuilder
import react.RComponent
import react.State
import react.dom.attrs
import react.dom.h1
import react.dom.p
import styled.*
import styles.MainStyles

@JsExport
class WhoAmI : RComponent<WhoAmIProps, WhoAmIState>() {
    init {
        state = WhoAmIState()
    }


    override fun RBuilder.render() {
        styledSection {
            css {
                backgroundColor = Color.darkGray.darken(65).changeAlpha(0.6)
            }
            container {
                mCssBaseline()
                attrs { id = "whoami" }
                css { +MainStyles.article }
                h1 { +"\$ whoami" }
                styledDiv {

                    mGridContainer {
                        mGridItem(xs = MGridSize.cells12, md = MGridSize.cells3) {
                            styledDiv {
                                css {
                                    margin(bottom = 1.em)
                                }
                                styledImg(alt = "me", src = "img/selfie1.jpg") {}
                            }

                        }
                        mGridItem(xs = MGridSize.cells12, md = MGridSize.cells9) {
                            styledDiv {
                                css {
                                    padding(horizontal = 1.em)
                                }
                                p { +"Hello World! " }
                                p {
                                    +"""Ich bin Christoph Landsky und möchte die Gelegenheit nutzen, mich kurz vorzustellen.
                    """.trimIndent()
                                }
                            }

                        }
                    }

                }
            }

        }
    }
}

//data class pages.WhoAmIState(val name: String) : State {}
class WhoAmIState() : State {}
external interface WhoAmIProps : Props {
    //val name: String
}

object WhoAmIStyle : StyleSheet("WhoAmI") {
}

fun RBuilder.whoami() {
    child(WhoAmI::class) {}
}