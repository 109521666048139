import BannerStyle.aiconLg
import BannerStyle.aiconSm
import components.mdi.*
import components.typed.Typed
import components.typed.typed
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.DIV
import kotlinx.html.id
import react.*
import react.dom.*
import styled.*

@JsExport
class Banner : RComponent<BannerProps, BannerState>() {

    lateinit var typedInstance: Typed

    val defaultTyped = "clsky"

    init {
        injectGlobal(AiconStyle.sticky)
        state = BannerState()
    }

    override fun componentDidMount() {
    }

    override fun componentWillUnmount() {
    }


    override fun RBuilder.render() {
        styledDiv {
            attrs { id = "bannerParent" }
            css { +BannerStyle.bannerParent }
            styledDiv {
                css { +BannerStyle.bannerContent }
                styledDiv {
                    css { +BannerStyle.heading }
                    a {
                        attrs {
                            onClick = {
                                var whoAmI = document.getElementById("whoami")
                                it.preventDefault()
                                whoAmI?.scrollIntoView(js("{ behavior: \"smooth\" }"))
                            }
                        }

                        span {
                            attrs { id = "typedAnchor" }
                            +"$ "
                        }
                        typed(strings = arrayOf(this@Banner.state.currentTyped), speed = 40) {
                            this@Banner.typedInstance = it
                        }
                    }
                }
                aiconBar()
                linkBar()
            }

        }
    }

    private fun RBuilder.linkBar() {
        styledDiv {
            css { +BannerStyle.linkBar }
            aicon("blog", mdiFountainPenTip, 1, href = "https://blog.clsky.de", target = "_blank", style = aiconSm)
            aicon("gitlab", mdiGitlab, 1, href = "https://gitlab.clsky.de/users/clsky/groups",  target = "_blank", style = aiconSm)
            aicon("twitter", mdiTwitter, 1, href = "https://twitter.com/_clsky",  target = "_blank", style = aiconSm)
            aicon("instagram", mdiInstagram, 1, href = "https://www.instagram.com/clsky.de/",  target = "_blank", style = aiconSm)
            aicon("youtube", mdiYoutube, 1, href = "https://www.youtube.com/user/ChristophLandsky",  target = "_blank", style = aiconSm)
            aicon("soundcloud", mdiSoundcloud, 1, href = "https://www.soundcloud.com/clandsky",  target = "_blank", style = aiconSm)
        }
    }

    private fun StyledDOMBuilder<DIV>.aiconBar() {
        styledDiv {
            css {
                +BannerStyle.aiconBar
            }
            attrs {
                id = "aiconBar"
            }
            val aiconSPAHandler: (MouseEvent<*,*>, String) -> Unit = { e, name ->
                e.preventDefault()
                var element = document.getElementById(name)
                element?.scrollIntoView(js("{ behavior: \"smooth\" }"))
            }
            aicon("whoami", mdiAccountTie, 1.8) { aiconSPAHandler(it, "whoami") }
            aicon("software", mdiCodeJson, 1.8) { aiconSPAHandler(it, "software") }
            aicon("media", mdiCamera, 1.8) { aiconSPAHandler(it, "media") }
            aicon("music", mdiHeadphones, 1.8) { aiconSPAHandler(it, "music") }
            aicon("writing", mdiBookshelf, 1.8) { aiconSPAHandler(it, "writing") }
        }
    }



    private fun RBuilder.aicon(name: String, iconPath: MDIconPath, size: Number = 1.8, style: RuleSet = aiconLg,
                               href: String = "#$name", target: String? = null,
                               onClick: (MouseEvent<*,*>) -> Unit = {}) {
        styledA(href = href, target = target) {
            css { +style }
            attrs {
                this.onClick = onClick
                this.onMouseEnter = {
                    this@Banner.setState {
                        currentTyped = name
                    }
                    this@Banner.typedInstance.reset(true)
                }
                this.onMouseLeave = {
                    this@Banner.setState {
                        currentTyped = this@Banner.defaultTyped
                    }
                    this@Banner.typedInstance.reset(true)
                }
            }
            mdIcon(iconPath, size = size)
        }
    }


}

//data class BannerState(val name: String) : State {}
data class BannerState(var currentTyped: String = "clsky") : State {

}

external interface BannerProps : Props {

}

object AiconStyle : StyleSheet("AiconStyle", isStatic = true) {
    val sticky by css {
        position = Position.absolute
        top = LinearDimension("0px")
    }
}

object BannerStyle : StyleSheet("BannerStyle") {
    val bannerParent by css {
        display = Display.flex
        justifyContent = JustifyContent.center
        alignItems = Align.center
        height = LinearDimension("100vh")
        //backgroundImage = Image("bg1.jpg")
        //background = "url(\"img/bg1.jpg\")"
        backgroundSize = "cover"
        backgroundRepeat = BackgroundRepeat.noRepeat
    }

    val aiconLg by css {
        media("only screen and (min-width: 768px)") {
            marginTop = 0.35.em
            marginLeft = 1.5.em
            marginRight = 1.5.em
        }
        media("only screen and (max-width: 768px)") {
            marginTop = 0.35.em
            marginLeft = 0.75.em
            marginRight = 0.75.em
        }
    }

    val aiconSm by css {
        media("only screen and (min-width: 768px)") {
            marginLeft = 1.2.em
            marginRight = 1.2.em
        }
        media("only screen and (max-width: 768px)") {
            marginLeft = 1.em
            marginRight = 1.em
        }
    }

    val bannerContent by css {
        display = Display.flex
        flexDirection = FlexDirection.column
//        background = "linear-gradient(rgba(82,126,160,.8),rgba(71,24,24,.8));"
        color = Color.white
        width = LinearDimension("100%")
        height = LinearDimension("100%")
        justifyContent = JustifyContent.center
        alignItems = Align.center
    }

    val aiconBar by css {
        media("only screen and (min-width: 768px)") {
            display = Display.flex
            flexDirection = FlexDirection.row
            minWidth = 400.px
            marginTop = 1.em
            marginBottom = 1.em
            borderTop = "2px solid white"
            borderBottom = "2px solid white"
            paddingBottom = 10.px
            paddingTop = 10.px
            alignContent = Align.center
            justifyContent = JustifyContent.center
            textAlign = TextAlign.center
        }
        media("only screen and (max-width: 768px)") {
            display = Display.flex
            flexDirection = FlexDirection.row
            maxWidth = 80.vw
            minWidth = 80.vw
            marginTop = 1.em
            marginBottom = 1.em
            borderTop = "2px solid white"
            borderBottom = "2px solid white"
            paddingBottom = 10.px
            paddingTop = 10.px
            alignContent = Align.center
            justifyContent = JustifyContent.center
            textAlign = TextAlign.center
        }
    }

    val linkBar by css {
        margin(top = 0.8.em)
    }

    val heading by css {
        fontSize = LinearDimension("3.2em")
        fontWeight = FontWeight.w400
    }
}

fun RBuilder.banner() {
    child(Banner::class) {}
}